import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { useTheme } from 'styled-components'

import imgTickets from '../assets/img-tickets.webp'

import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'

import { PageLayout } from '../styles/Layout'
import { LegalsStyled } from '../styles/LegalsStyled'
import { TitleS, BodyM, BodyS } from '../styles/Typography'

const Sustainability = () => {
  const theme = useTheme()

  const [isReverse, setIsReverse] = useState(false)

  const changeBackgroundColor = () => {
    const windowScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    const pageHeight = document.body.offsetHeight - (window.innerHeight + 300)

    if (windowScroll >= pageHeight) {
      setIsReverse(true)
    } else {
      setIsReverse(false)
    }
  }

  const onScroll = () => {
    window.requestAnimationFrame(changeBackgroundColor)
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <>
      <SEO
        title="Butik | Sustainability"
        description="Butik Electronic Music Festival"
        image={imgTickets}
      />
      <PageLayout
        backgroundColor={
          isReverse ? theme.backgroundDark : theme.backgroundPrimary
        }
      >
        <Header
          textColor={isReverse ? theme.backgroundPrimary : theme.backgroundDark}
        />
        <LegalsStyled>
          <div className="content">
            <div className="inner">
              <TitleS className="title">
                Sustainability at Butik Festival
              </TitleS>
              <BodyM className="text">
                Butik Festival is taking place in Soča Valley, at one of the
                most beautiful festival locations in the world. At Butik, you
                will be surrounded by breathtaking nature. This is why we want
                to take extra care of the environment.
              </BodyM>
              <BodyM className="text">
                The festival site is just a short walk from town Tolmin and
                there are a couple of other villages nearby. This means that our
                festival also impacts the lives of local people. The local
                community has been supportive throughout the years and we really
                appreciate this. We want to be able to organize the festival
                here for many years to come, so we try to do our best to lower
                the negative impacts.
              </BodyM>
              <BodyM className="text">
                We as the organizers cannot do this alone, so we kindly ask all
                festival goers to read through our measures related to
                sustainability.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                1. We respect nature and local community
              </BodyM>
              <BodyM className="text">
                The Tolmin town is a charming little town nestled in the
                mountains, which turns into the festival capital of Slovenia for
                a month in summer. For some, this is something that they
                appreciate, and for others, it is an unnecessary distraction in
                their routine. We are aware that music festivals come with loud
                music, so we agreed with the municipality to shut down outdoor
                music after 1 AM.
              </BodyM>
              <BodyM className="text">
                Like with everything else, there are always some prejudices
                about festivals and festival-goers, but so far, we are doing a
                great job of proving to locals that our festival-goers are very
                respectful to local communities and the environment. Thank you
                for cooperating with us and enabling our festival to continue
                here in this beautiful location.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                2. Giving back to the community - festival fund
              </BodyM>
              <BodyM className="text">
                Together with festival Punk Rock Holiday and Tolminator we
                created the 'Festival Fund' initiative. All the money that is
                left on paycards after the festival is donated to the local
                community. In the past two years, we donated more than 25.000 €.
                We let the locals decide which NGOs should receive the funds.
              </BodyM>
              <BodyM className="text">
                The selection was rather diverse - from rescue dogs, the local
                football club, breast cancer awareness organization,
                firefighters, association for helping people with disabilities,
                model gliders.. to humanitarian organizations for the
                underprivileged. And when we say 'we donated', this includes
                you, People of Butik. So thanks to everyone who joined the
                initiative. The money left on paycards is now in good hands.
              </BodyM>
              <BodyM className="text">
                The festival fund initiative is something that puts smiles on
                many faces (including ours), so we will continue with it in the
                future.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                3. Reusable cups and biodegradable packaging
              </BodyM>
              <BodyM className="text">
                In 2021 we finally found a provider for reusable cups and
                introduced them to the Butik Festival. This reduced waste
                drastically. All food vendors at the festival use biodegradable
                packaging for serving food.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                4. Cup deposit*
              </BodyM>
              <BodyM className="text">
                Everytime you order a drink from the bar you pay a 'cup deposit'
                that is returned to you when you return the container to the cup
                deposit station. This means that the festival site is clean
                throughout the whole festival. We don't want the festival site
                to look like a landfill at any time. We want everyone to enjoy
                the beautiful nature without plastic cups lying around.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                5. Garbage deposit*
              </BodyM>
              <BodyM className="text">
                Each person who stays in the festival campsite deposits a
                garbage deposit upon entry and receives two trash bags - one for
                plastic and one for mixed trash. Before leaving the festival,
                you return full bags to the garbage center and you will get the
                deposit back. The point of this measure is to equip all campers
                with garbage bags and motivate you to keep the festival site
                clean at all times. Again, the Butik campsite is such a dreamy
                place in nature - near the river and under the trees - please
                respect this and keep it clean at all times.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                6. Drinkable tap water*
              </BodyM>
              <BodyM className="text">
                There is drinking tap water available in the campsite and inside
                the festival area. This means that you don't have to bring water
                in plastic bottles from the supermarket. Just fill your
                containers.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                7. Rent-a-tent*
              </BodyM>
              <BodyM className="text">
                People from all around the world visit Butik, which means that
                there are many fly-ins. To avoid single use tents that are left
                behind on the festival site, we started offering a rent-a-tent
                service.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                8. Electricity in the campsite to avoid petrol generators*
              </BodyM>
              <BodyM className="text">
                We do not allow petrol generators in the campsite. First reason
                is to avoid petrol, second one is because they are really loud.
                The noise can be irritating to your camping neighbors, but it
                sure irritates locals from Tolmin who live close to the festival
                site. There were complaints in the past for the noise coming
                from the campsite throughout the whole night, so please keep
                that in mind also with your speakers.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                9. Clean up after season
              </BodyM>
              <BodyM className="text">
                Together with other festival organizers mentioned above we
                organize a clean up after the season, to really make sure that
                no trash was left behind.
              </BodyM>
              <BodyS className="text">
                * Check <Link to="/festival-a-z">Festival A-Z</Link> to get more
                info about this.
              </BodyS>
            </div>
            <Footer isReverse={isReverse} />
          </div>
        </LegalsStyled>
      </PageLayout>
    </>
  )
}

export default Sustainability
